/* Stepper Container */
.stepper-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Progress Bar Background */
.progress-bar-bg {
  position: absolute;
  top: 15px;
  left: 79px;
  right: 79px;
  height: 4px;
  background-color: #ddd;
  z-index: 1;
}

/* Filled Progress Bar */
.progress-bar-fill {
  height: 100%;
  background-color: #0d6efd;
  transition: width 0.5s ease-in-out;
}

/* Steps Wrapper */
.steps-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 0 25px;
}

/* Step Box */
.step-box {
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.step-box .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d6efd;
  font-weight: bold;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #0d6efd;
}

/* Completed Step */
.step-box.completed .circle {
  background-color: #fff;
  color: #fff;
}

.step-box.active .circle {
  background-color: #0d6efd;
  color: #fff;
  transform: scale(1.1);
}

.step-box .tick-mark {
  font-size: 14px;
  color: #0d6efd;
}

.step-title {
  font-size: 12px;
  color: #6c757d;
  margin-top: 5px;
  transition: color 0.3s ease;
  width: 125px;
}

.step-box.active .step-title {
  color: #0d6efd;
}
.custom-modal-dialog .modal-content {
  min-height: 600px;
}
.step-box .circle i {
  font-size: 16px;
  color: #0d6efd;
}
.step-box.active .circle i {
  color: #fff;
  font-size: 16px;
}
.nav.nav-tabs {
  background-color: white;
}
.nav-item .nav-link {
  color: #007bff !important;
}
.nav-item .nav-link.active {
  background-color: #7cb1ff;
  border: 1px solid #7cb1ff;
  border-radius: 0;
  color: white !important
}

.nav-item .nav-link.active-tab {
  background-color: #fff;
  border-radius: 0;
  border-bottom: 1px solid #007bff;
}

/* Onboard.css */
.custom-tabs .nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  background-color: #333;
  border-bottom: none;
}

.custom-tabs .nav-link {
  color: #b0c4de;
  font-size: 0.9rem;
  padding: 8px 12px;
  margin-right: 4px;
  background: transparent;
  border: none;
  transition: all 0.3s;
}

.custom-tabs .nav-link:hover {
  color: #fff; /* White on hover */
  background: rgba(255, 255, 255, 0.1); /* Slight hover effect */
}

.custom-tabs .nav-link.active {
  color: #fff !important; /* White text for the active tab */
  background: #007bff; /* Blue background */
  border-radius: 3px; /* Slightly rounded corners */
}

.custom-tabs .nav-tabs::-webkit-scrollbar {
  height: 4px; /* Thin scrollbar for horizontal scrolling */
}

.custom-tabs .nav-tabs::-webkit-scrollbar-thumb {
  background: #666; /* Dark scrollbar thumb */
  border-radius: 2px;
}

.custom-tabs .nav-tabs::-webkit-scrollbar-track {
  background: #333; /* Match tab background */
}
