.ck-content {
    min-height: 60px !important;
}

 .ck-toolbar_grouping {
    height: 25px !important;
}

.ck-toolbar {
    height: 40px !important;
}

.ck-toolbar__items {
    height: 20px !important;
    padding: 0px !important;
    margin-top: 0px;
    margin-bottom: 0px;
}

.ck .ck-tooltip__text {
    color: white !important;
}

