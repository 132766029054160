/* NotificationBell.css */
@keyframes blink {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  @keyframes ring {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-15deg); }
    50% { transform: rotate(15deg); }
    75% { transform: rotate(-10deg); }
    100% { transform: rotate(0deg); }
  }
  
  .ring-animation {
    animation: ring 0.5s infinite;
  }
  
  .notification-bell {
    cursor: pointer;
    color: lightgrey;
    margin-right: 0.4rem;
    margin-top: 0.3rem;
    font-size: 30px;
  }
  
  .notification-pill {
    animation: blink 1s infinite alternate;
    font-size: 10px;
    padding: 2px 5px;
    white-space: nowrap;
    top: -2px;
    right: -100px;
  }
  
  .notification-card {
    width: 29%;
    max-width: 30%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
    background-color: #fff;
    border-radius: 10px;
    top: 6rem;
  }
  
  .notification-card.visible {
    transform: translateX(0);
  }

  .error-card {
    display: flex;
    align-items: start;
    padding: 3px;
    border-radius: 5px;
    border: 1px solid red;
    margin-bottom: 3px;
  }
  
  .error-text {
    color: darkblue;
  }
  
 /* For tablets and smaller screens */
@media (max-width: 768px) {
    .notification-card {
      width: 60%;  
      max-width: 65%;
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .notification-card {
      width: 90%;  
      max-width: 95%;
      top: 5rem;  
    }

    .notification-pill {
        top: -5px;
    }
  }